import React, { useContext, useState, Fragment } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';

import { Page } from '../../../components/shared/Page';
import { Seo } from '../../../components/shared/Seo';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { FormGroup } from '../../shared/form/FormGroup';
import { ErrorMessage } from '../../shared/form/ErrorMessage';
import { Button } from '../../shared/Button';
import { FormInput } from '../../shared/form/FormInput';
import { FormGroupError } from '../../shared/form/FormGroupError';
import { email } from '../../../validations';

const ValidationSchema = object().shape({ email });

export const Email = ({ onSubmit }) => {
  const { generateResetLink } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);
  const [error, setError] = useState(null);

  const initialFormValues = {
    email: '',
  };

  const onFormSubmit = async (data, { setSubmitting }) => {
    try {
      setError(null);
      await generateResetLink(data);
      onSubmit(data);
    } catch (error) {
      if (error.isNotFound) {
        setSubmitting(false);
        return setError('Toto není váš mail použitý při registraci.');
      }

      setGlobalError(error);
    }
  };

  return (
    <Fragment>
      <Seo title="Zapomenuté heslo - mail" />
      <Page
        title="Zadejte mail"
        description="Zadejte mail, na který vám odešleme odkaz pro reset hesla."
      >
        <Formik
          initialValues={initialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={onFormSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            isSubmitting,
            isValid,
            dirty,
            errors,
            touched,
          }) => (
            <Form className="flex flex-col sm:max-w-sm mx-auto">
              <FormGroup className="mt-0">
                <FormInput
                  type="email"
                  name="email"
                  placeholder="Mail"
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  data-testid="forgotten-password-email-input"
                />
                <FormGroupError name="email" />
              </FormGroup>

              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting || !dirty || !isValid}
                border="normal"
                className="mt-6"
                data-testid="forgotten-password-submit"
              >
                Pokračovat
              </Button>

              {error && (
                <ErrorMessage
                  className="text-center pr-8"
                  data-testid="forgotten-password-email-custom-error"
                >
                  {error}
                </ErrorMessage>
              )}
            </Form>
          )}
        </Formik>
      </Page>
    </Fragment>
  );
};
