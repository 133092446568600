import React from 'react';
import PropTypes from 'prop-types';

import { Seo } from '../../../components/shared/Seo';
import Check from '../../../images/svg/check.svg';

export const Send = ({ email }) => (
  <div className="flex justify-center items-center flex-col w-full h-full">
    <Seo title="Zapomenuté heslo - mail odeslán" />
    <h2
      className="text-4xl px-6 text-center"
      data-testid="forgotten-password-send-description"
    >
      Na mail <span className="font-bold">{email}</span> jsme vám poslali odkaz
      pro reset hesla.
    </h2>
    <Check className="w-32 h-32 mt-8 fill-green" />
  </div>
);

Send.propTypes = {
  email: PropTypes.string,
};
